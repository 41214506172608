:root {
    --shadow-color: 0deg 0% 60%;
    --shadow-elevation-low: -0.1px -0.1px 0.2px hsl(var(--shadow-color) / 0), -0.4px -0.3px 0.8px hsl(var(--shadow-color) / 0.55);
    --shadow-elevation-medium: -0.1px -0.1px 0.2px hsl(var(--shadow-color) / 0), -1.9px -1.6px 3.7px hsl(var(--shadow-color) / 0.77);
    --shadow-elevation-high: -0.1px -0.1px 0.2px hsl(var(--shadow-color) / 0), -3.2px -2.6px 6.2px hsl(var(--shadow-color) / 0.63), -10px -8.2px 19.4px hsl(var(--shadow-color) / 1);
}

.JosephHeader {
    background-color: rgb(32, 26, 26);
    position: relative;
    height: 90%;
    width: 100%;
    overflow-x: hidden;
    -webkit-box-shadow: 0px 6px 15px 1px #000000;
    box-shadow: 0px 6px 15px 1px #000000;
}

.JosephHeader h1 {
    text-align: center;
    margin-top: 10%;
    font-size: 50px;
    font-weight: bold;
}

.rug {
    height: 0;
    width: 0;
    border-bottom: 0px solid transparent;
    border-top: 50vh solid transparent;
    border-right: 120vw solid rgb(189, 41, 10);
    position: absolute;
    bottom: 0vh;
    left: -20%;
}

.rug-shadow {
    height: 0;
    width: 0;
    border-bottom: 0px solid transparent;
    border-top: 55.5vh solid transparent;
    border-right: 125vw solid rgba(189, 40, 10, 0.397);
    position: absolute;
    bottom: 1%;
    left: -20%;
}

.header-picture-box-holder {
    width: 100%;
    bottom: 10%;
    position: absolute;
}

.header-picture-box {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1vw solid rgb(189, 41, 10);
    height: 40vmax;
    max-height: 60vh;
    width: 40vmax;
    max-width: 60vh;
    border-radius: 50%;
    position: relative;
    left: 1vw;
}

.ocean {
    height: 35vh;
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(189, 41, 10);
}

.wave {
    background: url("../../../Assets/headerWave.svg") repeat-x;
    position: absolute;
    top: -98px;
    width: 600vw;
    height: 198px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
    top: -75px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1;
}

@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}

@keyframes swell {
    0%,
    100% {
        transform: translate3d(0, -25px, 0);
    }
    50% {
        transform: translate3d(0, 5px, 0);
    }
}

@media only screen and (max-width:600px) {
    .header-picture-box {
        margin: auto;
    }
}

@media only screen and (max-height:600px) {
    .JosephHeader h1 {
        margin-top: 5%;
    }
}

.click-here {
    position: absolute;
    top: 90%;
    left: 80%;
    color: black;
    white-space: nowrap;
}

.click-here img {
    width: 50px;
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.click-here p {
    position: relative;
    left: 25px;
    font-size: 20px;
}