.Social img {
    height: 50px;
    width: 50px;
    background-color: rgba(255, 0, 0, 0.664);
    border-radius: 50px;
    border: 1px solid rgba(255, 0, 0, 0.664);
}

.Social {
    margin-left: 10px;
    margin-right: 10px;
    transition-duration: 500ms;
}

.Social:hover {
    transition-duration: 500ms;
    transform: scale(1.1);
}