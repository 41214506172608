.ProjectsBox a {
    color: white;
    margin: 10px;
}

.ProjectsBox a:hover {
    color: white;
}

.ProjectsBox {
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Project {
    border: 1px solid rgba(243, 106, 62, 1);
    align-self: center;
    width: 300px;
    height: 400px;
    max-height: 90vh;
    border-radius: 5px;
    -webkit-box-shadow: 4px 4px 15px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 4px 4px 15px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    transition-duration: 2000ms;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.Project>p {
    position: absolute;
    bottom: 19px;
    width: 55%;
    left: 0;
    text-align: right;
    background-color: rgba(243, 106, 62, 1);
    padding: 1px 5px 1px 5px;
    border-radius: 0rem 0.85rem 0rem 0rem;
    white-space: nowrap;
    transition: width 1.5s ease-in-out, bottom 2000ms ease-in-out;
    -webkit-transition: width 1.5s ease-in-out, bottom 2000ms ease-in-out;
    -moz-transition: width 1.5s ease-in-out, bottom 2000ms ease-in-out;
    -o-transition: width 1.5s ease-in-out, bottom 2000ms ease-in-out;
}

.Project:hover>p {
    transition: width 1.5s ease-in-out, bottom 800ms ease-in-out;
    -webkit-transition: width 1.5s ease-in-out, bottom 800ms ease-in-out;
    -moz-transition: width 1.5s ease-in-out, bottom 800ms ease-in-out;
    -o-transition: width 1.5s ease-in-out, bottom 800ms ease-in-out;
    bottom: -16px;
    width: 100%;
}

.Project .arrowIcon {
    height: 0.6em;
    width: 0.6em;
    transform: rotate(-90deg);
}

.Project h1 {
    position: relative;
    font-size: 25px;
    padding: 5px;
    z-index: 1;
    align-self: center;
    border-bottom: 0px !important;
    text-align: start;
    font-weight: 400;
    margin-top: 5px;
    white-space: nowrap;
}

.Project h2 {
    position: relative;
    z-index: 1;
    font-size: 12px;
    align-self: center;
}

.Project img {
    width: 300px;
    height: auto;
    cursor: pointer;
}

.projectHeaderBox {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    transition-duration: 2000ms;
}

.bleedingBackground {
    background-image: url('../../../Assets/wave-2.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 150px;
    position: absolute;
    z-index: 0;
    top: 0;
    pointer-events: none;
    transition-duration: 2000ms;
}

.tagBar {
    background-color: rgba(243, 106, 62, 1);
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    overflow-x: scroll;
    transition-duration: 2000ms;
}


/* Hide scrollbar for Chrome, Safari and Opera */

.tagBar::-webkit-scrollbar {
    display: none;
}


/* Hide scrollbar for IE, Edge and Firefox */

.tagBar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.Tag {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 15px;
    width: fit-content;
    background-color: rgb(30, 30, 30);
    border-radius: 5px;
    margin: 5px;
    padding: 2px 5px 2px 5px;
}

.Project:hover {
    transition-duration: 2000ms;
    transform: scale(1.01);
}

.Project:hover .tagBar {
    transition-duration: 2000ms;
    transform: translateY(1.5em);
}

.Project:hover .projectHeaderBox {
    transition-duration: 2000ms;
    transform: translateY(-110px);
}