.JosephNavbar {
    height: 10vh;
    position: sticky;
    top: 0;
    z-index: 10;
}

.menu_container {
    z-index: 1;
    position: sticky;
    top: 4%;
    width: 100%;
    height: 100%;
    text-align: center;
    pointer-events: none;
}

.menubtn {
    z-index: 1;
    width: 10%;
    height: 100%;
    background-image: url("../../../Assets/hamburgerButton.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    cursor: pointer;
    left: 1%;
    pointer-events: all;
}

.menu {
    background-color: rgba(255, 0, 0, 0.664);
    position: relative;
    padding-left: 10%;
    left: 0%;
    height: 100%;
    width: 100%;
    max-height: auto;
    text-align: center;
    pointer-events: none;
    transition: width 1s ease-in-out;
    overflow: hidden;
    -webkit-box-shadow: 0px 6px 15px 1px #000000;
    box-shadow: 0px 6px 15px 1px #000000;
    transition-delay: 500ms;
}

.menu * {
    transition: opacity 1500ms ease-in-out;
    transition-delay: 500ms;
}

.menu.minimized {
    transition-delay: 0ms;
    width: 12%;
    transition-duration: 2000ms;
}

.menu.minimized * {
    transition-delay: 0ms;
    opacity: 0;
    transition-duration: 500ms;
}

.item {
    color: white;
    display: inline-block;
    position: relative;
    top: 0%;
    background-color: transparent;
    border: 0;
    margin-top: 0.25%;
    height: 100%;
    line-height: 100%;
    width: auto;
    text-align: center;
    font-family: 'Bubbler One';
    font-style: oblique;
    font-weight: bolder;
    font-size: 6vmin;
    pointer-events: all;
}

.item.active {
    background-color: rgb(18, 18, 18);
    opacity: 1;
}

.item:hover {
    transform: scale(1.01, 1.01);
}

#section_title {
    opacity: 0;
    background-color: transparent;
    position: absolute;
    height: 100%;
    left: 15%;
    z-index: 20;
    transition: opacity;
    transition-duration: 500ms;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

#section_title.active {
    opacity: 0.5;
    transition: opacity;
    transition-duration: 1500ms;
    transition-delay: 500ms;
}

#section_title h1 {
    align-self: center;
    height: fit-content;
    font-family: 'Bubbler One';
    font-style: oblique;
    font-weight: bolder;
    font-size: 7vmin;
    margin-top: unset;
}

@media only screen and (max-width:600px) {}

@media only screen and (max-height:600px) {
    .item {
        font-size: 8vh;
    }
}