body {
    background-color: #121212;
    align-items: center;
    margin: 0%;
    -webkit-tap-highlight-color: transparent;
    font-family: 'Bubbler One';
    font-size: 24px;
    color: white;
    height: 100%;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

#root {
    height: 100%;
}

.JosephHome {
    height: 100%;
}

.myBackground {
    background-color: red;
    height: 110px;
    width: 100%;
    position: absolute;
}

.currentPositions img {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    border: 1px solid rgba(128, 128, 128, 0.514);
}

.contentCard {
    color: whitesmoke;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative;
}

.contentCard>h1 {
    text-align: start;
    font-weight: 400;
    margin-top: 5px;
    opacity: 1;
    transition: opacity ease-in-out;
    transition-duration: 500ms;
    transition-delay: 500ms;
    font-size: 40px;
}

.contentCard>h1.inactive {
    opacity: 0;
    transition-duration: 500ms;
}

.header_container {
    overflow: hidden;
    top: 0;
    width: 100%;
    height: 93vh;
    text-align: center;
}

.header_container p {
    position: sticky;
    top: 70%;
    font-style: italic;
    color: white;
}

#myName {
    font-size: 44px;
    text-align: start;
}

#photoofme {
    border-radius: 50%;
    width: 30%;
    max-width: 250px;
    margin: 0;
    float: none;
}

#aboutme {
    overflow: visible;
    height: auto;
    max-height: none
}

.contentBox {
    font-size: 19px;
    font-family: 'Sen', sans-serif;
    width: 95%;
    max-width: 800px;
    display: inline-block;
}

.sepLine {
    width: 100%;
    background-color: rgb(255, 0, 0, 0.3);
    height: 0.3%;
}

.aboutBox h1 {
    color: white;
    background-image: linear-gradient(transparent, rgb(255, 0, 0, 0.5), transparent);
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    margin-left: 25%;
    margin-right: 25%;
    border-radius: 5%;
    cursor: pointer;
}

.contentBox h3 {
    position: absolute;
    left: 0;
    margin: 0;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0;
    font-weight: bolder;
    font-size: 150%;
}

.itemBox {
    position: relative;
}

.contentBox h4 {
    font-size: 90%;
    position: relative;
    text-align: justify;
    margin: 0;
    margin-bottom: 10px;
    padding-top: 20px;
    font-weight: bold;
}

.contentBox p {
    margin: 0;
    max-width: inherit;
    text-align: justify;
    line-height: 150%;
}

.contentBox ul {
    text-align: justify
}

.itemBox .header {
    position: relative;
    height: 40px;
}

.header .year {
    position: absolute;
    right: 0;
    bottom: 0;
}

.main_container {
    width: 100%;
    max-width: 1400px;
    margin: auto;
    height: auto;
    text-align: center;
}

.flexContainer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
    -ms-flex-line-pack: star
}

.contentCard>p {
    background-color: rgb(30, 30, 30);
    padding: 40px;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 0.25rem;
    text-align: start;
    font-size: 18px;
    -webkit-user-select: text;
    /* Safari */
    -moz-user-select: text;
    /* Firefox */
    -ms-user-select: text;
    /* IE10+/Edge */
    user-select: text;
    /* Standard */
}

.qrCode {
    width: 125px;
    align-self: center;
    position: relative;
    top: -30px;
}

label {
    color: rgba(255, 255, 255, 0.664);
    font-size: 20px;
}

textarea {
    padding-top: 1.9rem !important;
    min-height: 200px !important;
    background-color: rgb(30, 30, 30) !important;
    border-color: rgb(30, 30, 30) !important;
}

input {
    padding-top: 1.9rem !important;
    background-color: rgb(30, 30, 30) !important;
    border-color: rgb(30, 30, 30) !important;
}

.form-control {
    color: whitesmoke !important;
}

.contactForm {
    padding: 20px;
    margin: 0px 30px 20px 20px;
    border-radius: 0.25rem;
}

.contactRow {
    padding: 20px;
    border-radius: 0.25rem;
}

.qrCol {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    float: right;
}

form button {
    background-color: rgba(255, 0, 0, 0.464) !important;
    border-color: rgba(255, 0, 0, 0.664) !important;
}

.arrowIcon {
    pointer-events: none;
    height: 0.5em;
    filter: invert(0.9);
    transition-duration: 300ms;
    transform: rotate(180deg);
}

.arrowIcon.down {
    transition-duration: 300ms;
    transform: rotate(0deg);
}

.centerContent {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width:600px) {
    .contactForm {
        padding: 0px;
        margin: 0px 0px 20px 0px;
        border-radius: 0.25rem;
    }
    .qrCode {
        display: none;
    }
}