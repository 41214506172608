.ExperienceCard {
    border-radius: 5px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: rgb(30, 30, 30);
    color: whitesmoke;
    padding: 10px;
    margin: 10px;
    -webkit-box-shadow: 4px 4px 15px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 4px 4px 15px 4px rgba(0, 0, 0, 0.2);
    width: fit-content;
    text-align: start;
    max-width: 500px;
}

.experiencePicture {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.ExperienceCard img {
    height: 100px;
    width: 100px;
    border-radius: 5px;
    vertical-align: middle;
    align-self: center;
}

.ExperienceCard h1 {
    font-size: 35px;
    font-weight: 500;
}

.ExperienceCard h2 {
    text-align: start;
    font-weight: 300;
    font-size: 25px;
}

.ExperienceCard h3 {
    text-align: start;
    font-weight: 200;
    font-size: 15px;
}

@media only screen and (max-width:600px) {
    .ExperienceCard h1 {
        text-align: center;
        font-size: 35px;
    }
    .ExperienceCard h2 {
        text-align: center;
        font-size: 25px;
    }
    .ExperienceCard h3 {
        text-align: center;
        font-size: 15px;
    }
}