.ProjectPage {
    color: whitesmoke;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.ProjectPage .header-picture-box {
    background-size: cover;
    background-position: top;
    transition-duration: 1000ms;
    cursor: pointer;
    border-color: rgb(189, 41, 10);
}

.ProjectPage .header-picture-box:hover {
    transition-duration: 1000ms;
    transform: scale(1.05);
}

.ProjectPage .JosephHeader {
    height: 90vh;
}

.goHome {
    background-image: url('../../../Assets/home-page.png');
    filter: invert(1);
    background-position: center;
    background-size: contain;
    background-color: transparent;
    border: none;
    width: 50px;
    height: 50px;
    transition-duration: 500ms;
    position: sticky;
    top: 90%;
    left: 90%;
    z-index: 100;
}

.goHome:hover {
    transform: scale(1.02);
    transition-duration: 500ms;
}

.projectPictureBox {
    cursor: pointer;
    position: fixed;
    overflow: hidden;
    height: 100%;
    top: 0;
    padding: 70px;
    z-index: 500;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: none;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.561);
}

.projectPictureBox:hover p {
    transform: scale(1.2);
}

.projectPictureBox img {
    pointer-events: none;
    height: 100%;
}

.projectPictureBox video {
    pointer-events: none;
    height: 100%;
}

.projectPictureBox.active {
    display: flex;
}

.projectPictureBox p {
    pointer-events: none;
    position: absolute;
    top: 35px;
    right: 35px;
    color: rgba(255, 0, 0, 1);
    font-weight: bolder;
}

.projectPictureBox p:hover {
    transform: scale(1.2);
}

.view-on-github {
    position: absolute;
    top: -100px;
    right: 2%;
    z-index: 5;
    font-size: 14px;
    color: black;
    background-color: rgba(255, 0, 0, 0.664);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 5px 5px 0px 5px;
    border-radius: 0.25rem;
    font-weight: 600;
}

.view-on-github img {
    width: 50px;
    top: -50px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transition-duration: 500ms;
}

.view-on-github:hover {
    transform: scale(1.02);
    transition-duration: 500ms;
}

.contentContainer {
    position: relative;
}

.readMe {
    margin-top: 50px;
}

.readMe p {
    font-size: 17px;
}

pre {
    background-color: rgb(32, 26, 26);
    padding: 5px;
    border-radius: 0.25rem;
}

pre code {
    font-size: 14px;
}